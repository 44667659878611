* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: 'Outfit', sans-serif;
}
.login-container {
    background: url("./assets/login_background.svg") no-repeat fixed;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
  }
  .container {
    background: #F6F6F6;
    width: 100%;
    position: relative;
    height: 100vh;
  }
  @media screen and (min-width: 680px) {
    .login-container {
      background: url("./assets/web_background.svg") no-repeat fixed;
      background-size: cover;
      min-height: 100vh;
      width: 100%;
    }
      .container {
        background: url("./assets/web_background.svg") no-repeat fixed;
        background-size: cover;
        min-height: 100vh;
        width: 100%;
      }
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #92CE08;
    border-radius: 40px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  input, select {
    -webkit-background-clip: text !important;
    background-clip:  text !important;
  }
  .small-swal {
    height: "200px" !important;
    display: flex !important;
    align-items: center !important;
    font-size: 0.7rem !important;
    width: 100% !important;
    padding: 0 20px !important;
  }
  .small-swal .swal2-icon {
    font-size: 0.4rem !important;
  }
  .small-swal .swal2-success-circular-line-left{
    display: none !important;
  }